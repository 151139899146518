<template>
  <div class="questionSubmit">
    <div class="questionSubmit-container">
      <div style="display: flex; justify-content: flex-start;">
        <el-page-header style="margin-bottom: 30px" @click.native="goBack" />

      </div>
      <div class="question-item">
        <div class="question-title">题目描述</div>
        <mavon-editor
          :value="fakeQuestionData.description"
          :subfield="mavonSetting.subfield"
          :default-open="mavonSetting.defaultOpen"
          :toolbars-flag="mavonSetting.toolbarsFlag"
          :editable="mavonSetting.editable"
          :scroll-style="mavonSetting.scrollStyle"
        />
      </div>

      <div class="question-item">
        <div class="question-title">数据输入</div>
        <mavon-editor
          :value="fakeQuestionData.inputFormat"
          :subfield="mavonSetting.subfield"
          :default-open="mavonSetting.defaultOpen"
          :toolbars-flag="mavonSetting.toolbarsFlag"
          :editable="mavonSetting.editable"
          :scroll-style="mavonSetting.scrollStyle"
        />
      </div>

      <div class="question-item">
        <div class="question-title">结果输出</div>
        <mavon-editor
          :value="fakeQuestionData.outputFormat"
          :subfield="mavonSetting.subfield"
          :default-open="mavonSetting.defaultOpen"
          :toolbars-flag="mavonSetting.toolbarsFlag"
          :editable="mavonSetting.editable"
          :scroll-style="mavonSetting.scrollStyle"
        />
      </div>

      <div class="question-item">
        <div class="question-title">样例</div>
        <mavon-editor
          :value="fakeQuestionData.example"
          :subfield="mavonSetting.subfield"
          :default-open="mavonSetting.defaultOpen"
          :toolbars-flag="mavonSetting.toolbarsFlag"
          :editable="mavonSetting.editable"
          :scroll-style="mavonSetting.scrollStyle"
        />
      </div>

      <div class="question-item">
        <div class="question-title">提示和限制</div>
        <mavon-editor
          :value="fakeQuestionData.limitAndHint"
          :subfield="mavonSetting.subfield"
          :default-open="mavonSetting.defaultOpen"
          :toolbars-flag="mavonSetting.toolbarsFlag"
          :editable="mavonSetting.editable"
          :scroll-style="mavonSetting.scrollStyle"
        />
      </div>

      <div style="display: flex; justify-content: space-between;">
        <el-button class="tag-read" :data-clipboard-text="fakeQuestionData.scalar" @click="copy">点击复制标程</el-button>
        <div style="display: flex">
          <el-button v-if="!options.readOnly" :disabled="btnDisabled" class="submit" @click="handleJudge">OJ判题</el-button>
          <el-button v-if="!options.readOnly" type="primary" :disabled="btnDisabled" class="submit" @click="submit">提交</el-button>
        </div>
      </div>

      <div style="font-size: 22px; margin-bottom: 20px" />
      <!--      <div>-->
      <!--        <span style="margin-right: 12px">选择语言</span>-->
      <!--        <el-select v-model="ojLanguage" clearable placeholder="请选择" style="margin-bottom: 20px" @change="changeLanguage">-->
      <!--          <el-option-->
      <!--            v-for="item in languageOption"-->
      <!--            :key="item.value"-->
      <!--            :label="item.label"-->
      <!--            :value="item.value"-->
      <!--          />-->
      <!--        </el-select>-->
      <!--      </div>-->
      <div class="inner">
        <div class="questionSubmit-container-top">
          <div style="color: red; font-weight: 600;">请在下方代码编辑区编写或粘贴你的代码，完毕后点击提交以保存(允许多次提交)。注意：直接返回将不会保存任何更改！</div>

          <div v-if="question.updateTime" style=" color: #988; font-weight: 600;">
            最后提交时间：{{ new Date(question.updateTime).toLocaleTimeString() }}
          </div>
        </div>
        <codemirror
          ref="jsonEditor"
          v-model="codeEditorData"
          :options="options"
        />

      </div>

    </div>
  </div>
</template>

<script>
import 'codemirror/lib/codemirror.css'

// language js
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/clike/clike.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/python/python.js'

// theme css
import 'codemirror/theme/monokai.css'
import 'codemirror/addon/selection/active-line'
import 'codemirror/keymap/sublime' // sublime编辑器效果
import 'codemirror/mode/css/css'
import 'codemirror/theme/yonce.css' // 编辑器主题样式
// 下面这几个引入的主要是验证提示类的,配置里的lint需要设置true,gutters: ['CodeMirror-lint-markers']
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/lint/json-lint'
import 'codemirror/addon/display/autorefresh'
import { getExamInfo, getQuestion, submitQuestion } from '@/api'
import { getQuestionsFromForeground } from '@/api/fake'
import Clipboard from 'clipboard'
import { submitCode } from '@/api/syzoj'

export default {
  name: 'Edit',
  data() {
    return {
      fakeQuestionData: {},
      mavonSetting: {
        subfield: false,
        defaultOpen: 'preview',
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true
      },
      ojLanguage: 'cpp11',
      btnDisabled: false,
      isSubmitLocked: true,

      options: {
        tabSize: 4,
        theme: 'yonce',
        lineNumbers: true,
        line: true,
        smartIndent: true,
        readOnly: false
      },
      codeEditorData: '',
      question: {
        index: undefined
      },
      examInfo: {},
      languageOption: [{
        value: 'cpp',
        label: 'C++'
      }, {
        value: 'cpp11',
        label: 'C++ 11'
      }, {
        value: 'cpp17',
        label: 'C++ 17'
      }, {
        value: 'cpp-noilinux',
        label: 'C++ (NOI)'
      }, {
        value: 'cpp11-noilinux',
        label: 'C++ 11(NOI)'
      }, {
        value: 'cpp11-clang',
        label: 'C++ 11(Clang)'
      }, {
        value: 'cpp17-clang',
        label: 'C++ 17(Clang)'
      }, {
        value: 'c',
        label: 'C'
      }, {
        value: 'c-noilinux',
        label: 'C (NOI)'
      }],

      judgeForm: {
        questionId: '',
        contestId: '',
        language: 'cpp',
        code: ''
      },
      fakeQuestionId: undefined
    }
  },

  computed: {
    id() {
      return this.$route.query.questionId
    },
    examId() {
      return this.$route.query.examId
    }
  },

  mounted() {
    this.fetchData()
    this.fetchFakeQuestion()
  },

  methods: {
    fetchData() {
      getQuestion({ questionId: this.id, classroomExamId: this.examId }).then(res => {
        this.question = res.data ?? { code: '' }
        this.codeEditorData = (res.data && res.data.code) ?? ''
        if (res.data) {
          this.ojLanguage = res.data.ojLanguage
        }
      })
        .catch(err => {
          this.$message.error(`获取试题状态失败[${err.toString()}]`)
        })
    },
    fetchFakeQuestion() {
      if (this.id === '1') {
        this.fakeQuestionId = 7673
      }

      if (this.id === '2') {
        this.fakeQuestionId = 111
      }

      if (this.id === '3') {
        this.fakeQuestionId = 112
      }
      getQuestionsFromForeground(this.fakeQuestionId).then(res => {
        this.fakeQuestionData = res.data.data
      })
    },

    submit() {
      if (this.isSubmitLocked) {
        this.isSubmitLocked = false
        const thisCopy = this
        submitQuestion({ classroomExamId: this.examId, questionId: this.id, code: this.codeEditorData, ojLanguage: this.ojLanguage })
          .then(() => {
            this.$message({
              message: '代码提交成功',
              type: 'success'
            })
            this.fetchData()
            setTimeout(function() {
              thisCopy.isSubmitLocked = true
            }, 3000)
          }).catch(e => {
            this.$message.warning(`提交失败: ${e.message ?? e.toString()}`)
          })
      } else {
        this.$message({ message: '别点了别点了，代码提交成功后3秒内请勿连续点击', type: 'error' })
        return
      }
    },

    goBack() {
      if (this.question.code !== this.codeEditorData) {
        this.$confirm(
          '当前代码存在未提交的改动，返回将会导致更改丢失。是否确定返回？',
          '警告',
          { type: 'warning', confirmButtonText: '放弃更改, 确定返回', cancelButtonText: '取消返回' }
        ).then(() => {
          this.$router.push({ path: '/tests' })
        }).catch(() => {})
      } else {
        this.$router.push({ path: '/tests' })
      }
    },

    changeLanguage(r) {
      this.ojLanguage = r
    },

    copy() {
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        console.log('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        console.log('该浏览器不支持复制')
        clipboard.destroy()
      })
    },

    handleJudge() {
      this.judgeForm.code = this.codeEditorData
      this.judgeForm.questionId = this.fakeQuestionId
      submitCode(this.judgeForm).then(res => {
        const routerJump = this.$router.resolve({ name: 'Judge', query: { sid: res.data.data }})
        window.open(routerJump.href, '_blank')
      })
    }

  }
}
</script>

<style scoped>
.questionSubmit-container {
  width: 1200px;
  margin: 80px auto;
}

.question-item {
  margin: 12px 0;
}

.question-title {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 18px;

}

.questionSubmit-container.inner {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.questionSubmit-container-top {
  height: 50px;
  padding: 0 10px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.submit {
  width: 5em;
  height: 3em;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .CodeMirror-scroll {
  font-size: 17px;
}

/deep/ .cm-s-yonce.CodeMirror {
  height: calc(100vh - 160px);
}

/deep/ .cm-s-yonce .CodeMirror-linenumber {
  color: #ffffff;
}

/deep/ .CodeMirror-linenumber .CodeMirror-gutter-elt {
  left: 20px;
}

/deep/.CodeMirror {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
</style>
